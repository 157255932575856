d = document;
num = parseInt;
function tag(o, v) {
	return (v) ? (o.tagName.toLowerCase() == v.toLowerCase()) : o.tagName.toLowerCase();
}


function make_tabs(tabs, pads, opts) {


	var o1 = this, s = s || 0,

	def = {
		t:300,
		eff:function() {this.fadeIn(def.t)},
		click:function(e) {
			if (this.tagName.toLowerCase() == 'a') {
				e.preventDefault();
				e.stopPropagation();
			}

			var o = $(this), _p = this._p;

			if (def.hide && _p.s[0] == this) {
				o.removeClass('s');
				this.pad.hide();
				_p.s = false;
				return (false);
			}

			if (_p.s) {
				_p.s.removeClass('s');
				_p.s[0].pad.hide();
			}

			o.addClass('s');

			_p.s = o;
			def.eff.apply(this.pad);
		},
		hide:false
	};
	if (opts) $.extend(def, opts);

	if (def.hide) s = -1;

	tabs.each(function(i, o) {
		o.pad = $(pads[i]);
		o._p = o1;
		o.i = i;

		var o = $(o).click(def.click)

		if (o.hasClass('s') && !s) s = i;

	});


	o1.s = (def.hide) ? false : $(tabs[s]).addClass('s');
	o1.tabs = tabs;
	pads.filter(function(i) {return i != s}).hide();

	return o1;
}

var set_animation = function() {

	var $body = $('body'), controller = $body.data('controller') || new ScrollMagic.Controller(/*{container:document.body}*/) , scenes = $body.data('scenes') || {};


	//set fixed header
	var $hdr = $(".hdr");

	if (!$hdr.data('animated')) {
		scenes.scene_hdr = new ScrollMagic.Scene({triggerElement: "body", triggerHook:0, offset:50})
			.setClassToggle($hdr[0], "fixed")
			.addTo(controller);
		$hdr.data('animated',1);
	}

	//other scenes desktop mode
	if (!$body.data('animated') && window.innerWidth >= 1010) {

		/*scenes.pct = new ScrollMagic.Scene({triggerElement: '#sect3 .n', triggerHook: 0, offset:300, duration: 600})
                .on('progress', function(e) {
                    var j, x,r = 600, PI=Math.PI;
                							j=(180*(e.progress||0));
                							x = Math.cos(j*(PI/180))*r+Math.sin((j+180)*(PI/180))*(r/2)
                							$('.ino_pct').css('transform', 'translateX('+(x+r)+'px)')
                						})
				//.on('start', function(e) {console.log(e.target.triggerElement())})

				.addTo(controller);
				scenes.pct.trigger('progress');*/





		var show_title = function (e) {
			$(e.target.triggerElement()).css('opacity', (1 * e.progress))
                }, $title;

		$('.sect').each(function (i) {

            $title = $('.title', this)
			scenes['scene_'+this.id] = new ScrollMagic.Scene({
					triggerElement: $title[0],
					triggerHook: 0.7,
					duration: 300
				})
				//.on('enter', function(e) {$('.abstr_hold', e.target.triggerElement()).addClass('show')})
				.on('progress', show_title)
				.addTo(controller)

			var r = 2000, scene_dur = 300, $bq = $('.abstr_hold', this)/*.css('transform', 'translateX('+(r)+'px)'), x, PI = Math.PI, j;*/

			/*if (i==2) {
				$bq = $('.abstr_hold', this).css('transform', 'translateX('+(r)+'px)');
				scene_dur = 2000;
			}*/

			if ($bq.length) {
				/*scenes['scene_block_'+this.id] = new ScrollMagic.Scene({triggerElement: '#sect'+(i+1)+' .n', triggerHook:0, offset:0, duration: scene_dur})
						.setPin('#pt'+(i+1))
						.addTo(controller);*/

				scenes['scene_bq_'+this.id] = new ScrollMagic.Scene({
					triggerElement: $bq[0],//$('.n',this)[0],
                    offset:0,
					triggerHook:.7,
					duration: 300
				})
                        .on('progress', function(e) {

							//j=(180*e.progress);
							//x = Math.cos(j*(PI/180))*r+Math.sin((j+180)*(PI/180))*(r/2)
							//$bq.css('transform', 'translateX('+(x+r)+'px)')
                            $bq.css('opacity', e.progress);
						})
						.addTo(controller)

				scenes['scene_bq_'+this.id].progress();

			}
		})

		var video_tween = function (e) {
            var $this = $(e.target.triggerElement())
			$this.find('.block').css('top', -(100 * e.progress) + '%');
            $this.find('.container').css('top', (540 * e.progress) + 'px');
		}

		$('.video').each(function (i) {
			var $this = $(this), $block = $(this).find('.block');

             $this.find('video').each(function() {if (!this.played) this.play()})

			scenes['scene_video'+i] = new ScrollMagic.Scene({
					triggerElement: this, triggerHook:.5, duration: window.innerHeight *1.1/**0.7*/
				})/*.on('start', function () {
					$this.find('.block').addClass('show')
				})*/
				.on('progress', video_tween)
				.addTo(controller);
		})

		$body.data('animated',1)
	}

	$body.data('controller', controller).data('scenes', scenes);
},

clear_animation = function() {
	var scenes = $('body').data('scenes');
	if (scenes) {
		for (var i in scenes) {
			if (i != 'scene_hdr') {
				scenes[i].destroy(true);
				delete scenes[i]
			}
			$('body').removeData('animated');
            $('video').each(function() {this.pause()})
		}

		if (scenes.scene_hdr && this.innerWidth<=700) {
			scenes.scene_hdr.destroy(true);
			$(".hdr").removeData('animated');

		}
	}

},

set_motto = function(texts) {
	var i = 0, t1, current = texts[i], pause, scrolled, o = $('.motto'), pos, show_text = function() {

		clearTimeout(t1)

		if (pause) return (false);
		o.text(current.substr(0,o.text().length+1));

		if (o.text().length==current.length) {o.addClass('pause'); t1=setTimeout(reset_motto, 2000)} else { t1=setTimeout(show_text, 50)}
	},
	reset_motto = function() {
		clearTimeout(t1)
		o.empty();
		(i<texts.length-2)?i++:i=0;
		current = texts[i];
        o.removeClass('pause')
        //setTimeout(function() {o.addClass('pause');}, 100);
		t1=setTimeout(show_text, 2000)
	}

	show_text();

	$(window).on('scroll', function() {
		pos = o.offset().top, scrolled = document.documentElement.scrollTop || document.body.scrollTop;
		if (pos>scrolled+window.innerHeight||pos<scrolled) {
			pause = 1;
		} else {
			pause = 0;
			show_text();
		}
	})

}

function set_quotes() {
	var html, tag = ['<span>','</span>'], $quotes = $('blockquote').each(function() {
		html = tag[0]+$.trim($(this).text()).split(' ').join('</span><span>')+tag[1];
		$(this).html(html);
	})
}

var set_team = function() {
	var o = $('.team'), itms = o.find('.sprite')/*o.find('.itm'), $block = o.find('.block'), open, hid,  n = 3, i*/, f = function() {

		/*open = itms.filter('.open');

		n = Math.round(Math.random()*(5-3))+3

		for (i=0; i<n; i++) {
			hid = itms.not('.open');
			hid.eq(Math.round(Math.random() * (hid.length-1))).addClass('open');

		}

		open.removeClass('open');*/

        o.find('.sprite.open').removeClass('open');
        itms.eq(Math.round(Math.random() * (itms.length-1))).addClass('open');



		setTimeout(f, 3000);
	}
	f();

	/*$(window).resize(function() {
		$block.css('transform','scale(1)');
		$block.css('transform','scale('+(o.width()/$block.width())+')')
	}).resize();*/
}

$(function() {

	var $c_popup = $('.contacts')/*.click(function() {$(this).addClass('hidden')})*/, $body = $('body'), $menu = $('.main_menu');

    $body.on('click', function() {$c_popup.addClass('hidden')})

    $c_popup.on('click', '#map1,ymaps,input,a,.row', function(e) {e.stopPropagation()})
    $('.contacts_sw').click(function(e) {

        $c_popup.toggleClass('hidden');

       var pos = $c_popup.offset().top//, scrolled = document.documentElement.scrollTop || document.body.scrollTop;
        		//if (pos>scrolled+window.innerHeight||pos<scrolled) {
                     $('body, html').animate({scrollTop:(pos<window.innerHeight/2)? 0 : pos}, 1000, 'swing');
        		//}
        return (false);

    })

	$('.timeline').on('click', 'strong', function() {$(this).closest('li').toggleClass('open')})


	$menu
        .on('click', function() {$('.hdr.fixed').toggleClass('open')})

        .on('click','a', function(e) { $('body, html').animate({scrollTop:$(this.hash).offset().top}, 1000, 'swing'); return (false)});

	set_quotes();


    if (window.innerWidth>=1100) set_animation();

	set_team();

	set_motto(['Медиапланирование',
	'Медиаразмещение',
	'Медиааналитика',
	'Digital-маркетинг',
	'Событийный маркетинг',
	'Бренд-маркетинговые коммуникации',
	'Интегрированные маркетинговые коммуникации']);

	$(window).on('resize', function() {
		if (this.innerWidth<=1100) {
			clear_animation();
		} else {
			set_animation();
		}
	})



})